import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  MenuItem,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import { fRange } from "../../../utils/formatNumber";
// utils
import {
  ANALYTICS_FILTER_TYPES,
  ANALYTICS_FILTER_TYPES_REPORT,
  MONTHS,
} from "../../../utils/apis";

Filter.propTypes = {
  title: PropTypes.string,
  handleChange: PropTypes.func,
  handleClick: PropTypes.func,
  values: PropTypes.object,
  hidePrint: PropTypes.bool,
  branches: PropTypes.array,
  cashiers: PropTypes.array,
  isReport: PropTypes.bool,
};
export default function Filter({
  title,
  values,
  handleChange,
  handleClick,
  hidePrint,
  branches,
  cashiers,
  isReport,
}) {
  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        height: "100%",
      }}
    >
      <CardHeader title={`Filter ${title}`} />
      <Stack
        spacing={4}
        sx={{ p: 3, height: "90%", width: 1 }}
        justifyContent="space-between"
      >
        <Stack direction="column" spacing={3}>
          {values.filterType && (
            <TextField
              select
              label="Filter By Type"
              name="filterType"
              fullWidth
              value={values.filterType}
              onChange={handleChange}
            >
              {[
                { value: "all", name: "All" },
                { value: "sales", name: "Sales" },
                { value: "invoices", name: "Invoices" },
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          {branches && branches.length > 0 && (
            <TextField
              select
              label="Filter Branch"
              name="branch"
              fullWidth
              value={values.branch}
              onChange={handleChange}
            >
              {[{ bid: "all", name: "All" }, ...branches].map((option) => (
                <MenuItem key={option.bid} value={option.bid}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}

          {cashiers && cashiers.length > 0 && (
            <TextField
              select
              label="Filter by Cashier"
              name="cashier"
              fullWidth
              value={values.cashier}
              onChange={handleChange}
            >
              {[{ uid: "all", name: "All" }, ...cashiers].map((option) => (
                <MenuItem key={option.uid} value={option.uid}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}

          <TextField
            select
            label="Filter Type"
            name="type"
            fullWidth
            value={values.type}
            onChange={handleChange}
          >
            {(isReport
              ? ANALYTICS_FILTER_TYPES_REPORT
              : ANALYTICS_FILTER_TYPES
            ).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {values.type === "date" && (
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              type="date"
              fullWidth
              label="Enter Date"
              name="value"
              value={values.value}
              onChange={handleChange}
            />
          )}
          {values.type === "month" && (
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              sx={{ width: 1 }}
              justifyContent="space-between"
            >
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                select
                fullWidth
                label="Month"
                name="month_month"
                value={values.value ? values.value.split("_")[0] : ""}
                onChange={handleChange}
              >
                {MONTHS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                select
                label="Year"
                fullWidth
                name="month_year"
                value={
                  values.value && values.value.split("_").length > 1
                    ? values.value.split("_")[1]
                    : ""
                }
                onChange={handleChange}
              >
                {fRange(new Date().getFullYear(), 1970, 1).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          )}
          {values.type === "year" && (
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              select
              label="Year"
              fullWidth
              name="value"
              value={values.value}
              onChange={handleChange}
            >
              {fRange(new Date().getFullYear(), 1970, 1).map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
          {values.type === "range" && (
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                fullWidth
                label="Date From"
                name="range_second"
                value={
                  values.value && values.value.split("_").length > 1
                    ? values.value.split("_")[1]
                    : ""
                }
                onChange={handleChange}
              />
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                fullWidth
                label="Date To"
                name="range_first"
                value={values.value ? values.value.split("_")[0] : ""}
                onChange={handleChange}
              />
            </Stack>
          )}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          spacing={2}
        >
          {!hidePrint && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                window.print();
              }}
              disableElevation
            >
              Print
            </Button>
          )}
          <Button
            disableElevation
            disabled={
              !(
                values.type &&
                (values.value ||
                  values.type === "today" ||
                  values.type === "week" ||
                  values.type === "current_month" ||
                  values.type === "current_year" ||
                  values.type === "all_time")
              )
            }
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Apply
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
}
